import React from 'react';
import { isBrowser } from '~/utils';

type Size = {
  width?: number;
  height?: number;
};

type UseWindowSize = () => Size;

const useWindowSize: UseWindowSize = () => {
  // We don't initialize state with undefined width/height anymore,
  // this might cause a rendering issue: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<Size>(isBrowser ? {
    width: window?.innerWidth,
    height: window?.innerHeight,
  } : {});
  const handleResize = React.useCallback(() => {
    setWindowSize({
      width: window?.innerWidth,
      height: window?.innerHeight,
    });
  }, []);
  React.useEffect(() => {
    handleResize();
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, [handleResize]);
  return windowSize;
};

export default useWindowSize;

/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { useDataContext } from '~/contexts/Data';

export const INDEXER_URLS = [
  // 'https://api.hicdex.com/v1/graphql',
  // 'https://hicdex.teztools.net/v1/graphql',
  // 'https://hdapi.teztools.io/v1/graphql',
  // 'https://hdnet.teztools.net/v1/graphql',
  // 'https://api.teia.rocks/v1/graphql',
  'https://api-v5.teia.rocks/v1/graphql',
];

export async function graphqlClient<T>(
  query: RequestDocument,
  params?: any,
) {
  return request<T>(
    INDEXER_URLS[0],
    query,
    params,
  );
}

const useGraphqlClient = () => {
  const { indexer, setIndexerError } = useDataContext();
  async function innerGraphqlClient<T>(
    query: RequestDocument,
    params?: any,
  ) {
    return request<T>(
      indexer,
      // INDEXER_URLS[0],
      query,
      params,
    ).catch(() => {
      setIndexerError(true);
    }) as any;
  }
  return innerGraphqlClient;
};

export default useGraphqlClient;

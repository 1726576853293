import styled from 'styled-components';
import LoaderStyle from '~/styles/Loader';

const TextLoader = styled.div<{ main?: boolean }>`
  width: 100px;
  height: 10px;
  border-radius: 0px;
  ${LoaderStyle}
`;

export default TextLoader;

/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useInfiniteQuery, useQuery } from 'react-query';
import React from 'react';
import { isAudioObjkt, MIMETYPE } from '~/utils/mime';
import { tokenFragmentLite, transformToken } from '~/graphql';
import { orderByFix } from '~/utils';
import { DIVIDER } from '~/utils/const';
import { useDataContext } from '~/contexts/Data';
import { useAudioPlayer } from '~/contexts/AudioPlayer';
import { useFilterQueryParams } from './useFilter';
import { Objkt } from '~/types';
import useGraphqlClient from './useGraphqlClient';

export type FilterFormData = {
  mime: string[] | string;
  mimeTypes: string[];
  minSupply: number;
  maxSupply: number;
  minPrice: number;
  maxPrice: number;
  q: string;
  tag: string;
  limit: number;
  isForSale: boolean;
  showBanned: boolean;
  orderBy: { [k: string]: 'asc' | 'desc' };
  orderKey: string;
  orderDir: 'asc' | 'desc';
  owner: string;
  creators: string[];
  objktIds: number[];
  exclude?: number[];
  offset?: number;
};

const SearchObjktsQuery = gql`
  query SearchObjktsQuery(
    $where: token_bool_exp,
    $orderBy: [token_order_by!] = { id: desc },
    $limit: Int,
    $offset: Int,
  ) {
    token(
      where: $where,
      order_by: $orderBy,
      limit: $limit,
      offset: $offset
    ) {
      ${tokenFragmentLite}  
    }
  }
`;

const SearchObjktsCountQuery = gql`
  query SearchObjktsCountQuery(
    $where: token_bool_exp,
  ) {
    token_aggregate(
      where: $where,
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getSearchKey = ({
  q,
  mime = [],
  minSupply,
  maxSupply,
  minPrice,
  maxPrice,
  isForSale,
  showBanned,
  tag,
  owner,
  creators = [],
  objktIds = [],
  orderBy,
  orderKey,
  orderDir,
  limit,
}: Partial<FilterFormData>): string[] => [
  'search',
  ['q', q].filter(Boolean).join('|'),
  ['mime', (mime.length > 0 ? (mime as string[]).sort() : Object.values(MIMETYPE).sort())].filter(Boolean).join('|'),
  ['minSupply', minSupply].filter(Boolean).join('|'),
  ['maxSupply', maxSupply].filter(Boolean).join('|'),
  ['minPrice', minPrice].filter(Boolean).join('|'),
  ['maxPrice', maxPrice].filter(Boolean).join('|'),
  ['isForSale', isForSale ? 'y' : 'n'].filter(Boolean).join('|'),
  ['showBanned', showBanned ? 'y' : 'n'].filter(Boolean).join('|'),
  ['tag', tag].filter(Boolean).join('|'),
  ['owner', owner].filter(Boolean).join('|'),
  ['creators', ...creators.sort()].filter(Boolean).join('|'),
  ['objktIds', ...objktIds.sort()].filter(Boolean).join('|'),
  ['orderBy', JSON.stringify(orderBy)].filter(Boolean).join('|'),
  ['orderKey', orderKey].filter(Boolean).join('|'),
  ['orderDir', orderDir].filter(Boolean).join('|'),
  ['limit', limit].filter(Boolean).join('|'),
].filter(Boolean);

export const useGetSearchKey = () => {
  const { blockedWallets } = useDataContext();
  return (v) => [...getSearchKey(v), ['blocked', ...blockedWallets]];
};

/*
query Search($id: bigint, $title: String) {
  tokens: token(
    limit: 5
    order_by: {id: desc}
    where: {_or: [{id: {_eq: $id}}, {title: {_ilike: $title}}]}
  ) {
    id
    title
    supply
    __typename
  }
}
*/

export const getSearchParams = ({
  q,
  mimeTypes = [],
  minSupply,
  maxSupply,
  minPrice,
  maxPrice,
  tag,
  isForSale,
  showBanned,
  creators = [],
  objktIds = [],
  exclude = [],
  orderKey,
  orderDir,
  limit,
  offset,
}: Partial<FilterFormData>, blockedWallets: string[]) => ({
  orderBy: ['acquiredAt', 'minPrice'].includes(orderKey) || !orderKey || !orderDir ? {
    id: 'desc',
  } : { [orderKey]: orderDir },
  limit,
  offset,
  where: {
    /*
    ...(+q > 0 ? {
      id: {
        _eq: q,
      },
    } : q ? {
      _or: {
        title: {
          _ilike: `%${encodeURIComponent(q)}%`,
        },
        description: {
          _ilike: `%${encodeURIComponent(q)}%`,
        },
      },
    } : {}
    ),

    query Search($id: bigint, $title: String) {
  tokens: token(
    limit: 5
    order_by: {id: desc}
    where: {_or: [{id: {_eq: $id}}, {title: {_ilike: $title}}]}
  ) {
    id
    title
    supply
    __typename
  }
}
    */
    ...(q ? {
      _or: [
        +q > 0 ? {
          id: {
            _eq: +q,
          },
        } : null,
        {
          title: {
            _ilike: `${q}%`,
          },
        },
      ].filter(Boolean),
    } : {}),
    artifact_uri: {
      _neq: '',
    },
    supply: {
      _gt: 0,
      ...(+minSupply ? {
        _gte: +minSupply,
      } : {}),
      ...(+maxSupply ? {
        _lte: +maxSupply,
      } : {}),
    },
    ...(mimeTypes.filter(Boolean).length > 0 ? {
      mime: {
        _in: mimeTypes.filter(Boolean),
      },
    } : {}),
    ...(isForSale || minPrice || maxPrice ? {
      swaps: {
        _and: {
          status: {
            _eq: '0',
          },
          contract_version: {
            _eq: '2',
          },
          price: {
            ...(minPrice ? {
              _gte: (+minPrice || 10000000) * DIVIDER,
            } : {}),
            ...(maxPrice ? {
              _lte: (+maxPrice || 10000000) * DIVIDER,
            } : {}),
          },
        },
      },
    } : {}),
    ...(tag ? {
      token_tags: {
        tag: {
          tag: {
            _eq: tag,
          },
        },
      },
    } : {}),
    ...(creators.filter(Boolean).length > 0 ? {
      creator_id: {
        _in: creators.filter(Boolean),
      },
    } : {}),
    _and: [
      objktIds.filter(Boolean).length > 0 ? {
        id: {
          _in: objktIds.filter(Boolean),
        },
      } : null,
      exclude.filter(Boolean).length > 0 ? {
        id: {
          _nin: exclude.filter(Boolean),
        },
      } : null,
    ].filter(Boolean),
    ...(showBanned ? {} : {
      creator: {
        address: {
          _nin: blockedWallets,
        },
      },
    }),
  },
});

export const useGetSearchParams = () => {
  const { blockedWallets = [] } = useDataContext();
  return (v) => getSearchParams(v, blockedWallets);
};

export const defaultInitialValues = {
  limit: 20,
  offset: 0,
};

export function getItemsFromQuery<T>(query) {
  const { pages = [] } = query.data || {};
  const tokens = pages.reduce((arr, tokens_) => [...arr, ...tokens_], []);
  return tokens as T[];
}

export const getNextPageParam = (_, allPages) => allPages.reduce((count, pages) => count + pages.length, 0) ?? 0;

const useSearchObjkts = (propValues = {}) => {
  const { setPlaylist } = useAudioPlayer();
  const getSearchKey_ = useGetSearchKey();
  const getSearchParams_ = useGetSearchParams();
  const { blockedWallets } = useDataContext();
  const values = useFilterQueryParams();
  const allValues = { ...values, ...propValues };
  const gqlClient = useGraphqlClient();
  const query = useInfiniteQuery(
    getSearchKey_(allValues),
    async ({ pageParam: offset }) => {
      const options = getSearchParams_({ ...allValues, offset });
      const { token = [] } = await gqlClient(
        SearchObjktsQuery,
        options,
      );
      const { orderKey, orderDir } = allValues;
      return orderByFix(token.map((t) => transformToken(t, blockedWallets)), [orderKey], [orderDir]);
    },
    {
      enabled: true,
      getNextPageParam,
    },
  );
  const tokens = getItemsFromQuery<Objkt>(query);
  React.useEffect(() => {
    setPlaylist(tokens.filter(isAudioObjkt));
  }, [setPlaylist, JSON.stringify(tokens)]);
  return { ...query, tokens };
};

export default useSearchObjkts;

export const useSearchObjktsCount = (propValues = {}) => {
  const getSearchKey_ = useGetSearchKey();
  const values = useFilterQueryParams();
  const allValues = { ...values, ...propValues };
  const getSearchParams_ = useGetSearchParams();
  const searchKey = getSearchKey_(allValues);
  const searchParams = getSearchParams_(allValues);
  const gqlClient = useGraphqlClient();
  const query = useQuery(
    ['count', ...searchKey],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await gqlClient<{ token_aggregate: any }>(
        SearchObjktsCountQuery,
        { where: searchParams.where },
      );
      const { token_aggregate } = res || {};
      return token_aggregate?.aggregate?.count ?? 0;
    },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

export const useCreatorCount = (address: string) => {
  const gqlClient = useGraphqlClient();
  const query = useQuery(
    ['count', 'creator', address],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await gqlClient<{ token_aggregate: any }>(
        SearchObjktsCountQuery,
        {
          where: {
            creator_id: {
              _eq: address,
            },
            artifact_uri: {
              _neq: '',
            },
            supply: {
              _gt: 0,
            },
          },
        },
      );
      const { token_aggregate } = res || {};
      return token_aggregate?.aggregate?.count ?? 0;
    },
    { enabled: !!address },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

import { css } from 'styled-components';

const LoaderStyle = css`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.foreground};
  &::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${({ theme }) => theme.colors.opacity} 50%,
      transparent 100%
      );
    animation-name: gradient-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    filter: blur(5px);
  }
  @keyframes gradient-animation {
    from {
      left: -100%;
    }
    to {
      left: 100%;
    }
  }
`;

export default LoaderStyle;

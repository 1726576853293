import React from 'react';
import styled from 'styled-components';
import { useBreakpointValue } from '~/hooks/useBreakpoint';
import LoaderStyle from '~/styles/Loader';
import Avatar from './Avatar';
import FlexRow from './FlexRow';
import TextLoader from './TextLoader';

const AvatarLoader = styled(Avatar)`
    ${LoaderStyle}
`;

const TokenCreatorDataLoader: React.FC<{}> = () => {
  const size = useBreakpointValue({ fallback: 34 });
  return (
    <FlexRow style={ { marginTop: 12, marginBottom: 0 } }>
      <div style={ { marginRight: 10 } }>
        <AvatarLoader size={ size } />
      </div>
      <div>
        <TextLoader style={ { marginBottom: 6 } } />
        <TextLoader />
      </div>
    </FlexRow>
  );
};

export default TokenCreatorDataLoader;

import styled from 'styled-components';
import RatioBox from '~/components/RatioBox';

const NftImageContainer = styled(RatioBox)`
  background-color: ${({ theme }) => theme.colors.foregroundLight};
  overflow: hidden;
  position: relative;
`;

export default NftImageContainer;

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const ContainerBlock = styled.div`
  display: block;
  overflow: hidden;
`;

const Container = styled.div<{ ratio: number; }>`
  height: 0;
  position: relative;
  width: 100%;
  padding-top: ${({ ratio }) => (ratio === 0 ? 100 : 100 / ratio)}%;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

type RatioBoxProps = {
  ratio?: number;
  className?: string;
  children: ReactNode;
};

const RatioBox: FC<RatioBoxProps> = ({
  ratio = 1,
  className,
  children,
  ...restProps
}) => (
  <ContainerBlock className={ className } { ...restProps }>
    <Container ratio={ ratio }>
      <Content>
        { children }
      </Content>
    </Container>
  </ContainerBlock>
);

export default RatioBox;

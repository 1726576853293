import styled from 'styled-components';

const ShowMoreButton = styled.button.attrs({
  type: 'button',
})`
    all: unset;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
`;

export default ShowMoreButton;

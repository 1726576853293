/* eslint-disable @typescript-eslint/naming-convention */
import styled from 'styled-components';
import React from 'react';
import { media } from '~/styles/media';
import NftImageContainer from './TokenAsset/components/NftImageContainer';
import LoaderStyle from '~/styles/Loader';
import { useDataContext } from '~/contexts/Data';
import TextLoader from './TextLoader';
import Button, { buttonHeight } from './Button';
import TokenCreatorDataLoader from './TokenCreatorDataLoader';

const TokenContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
`;

const NftImageContainerLoader = styled(NftImageContainer)`
  ${LoaderStyle}
`;

const TokenText = styled.div`
  position: relative;
  margin-top: 3px;
  display: block;
  padding: 5px 0;
  ${media.desktop`
    padding: 5px 15px;
  `};
`;

const StyledTextLoader = styled(TextLoader)`
  margin-top: 2px;
`;

const ButtonLoader = styled(Button)`
  width: 100%;
  transform: translateY(8px);
  height: ${buttonHeight + 4}px;
  ${LoaderStyle}
`;

const TokenLoader: React.FC<{}> = () => {
  const { layout } = useDataContext();
  return (
    <TokenContainer>
      <NftImageContainerLoader>
        <div />
      </NftImageContainerLoader>
      {
        layout === 'gallery' ? null : (
          <>
            <TokenCreatorDataLoader />
            <TokenText>
              <StyledTextLoader style={ { marginBottom: 8 } } />
              <StyledTextLoader style={ { marginBottom: 8 } } />
            </TokenText>
            <ButtonLoader />
          </>
        )
      }
    </TokenContainer>
  );
};

export default TokenLoader;

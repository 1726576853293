import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { replaceIPFS } from '~/utils';
import { media } from '~/styles/media';

export const avatarSize = 100;
export const avatarSizeMobile = 85;

export type AvatarContainerProps = { $size?: number; };

export const AvatarContainer = styled.div<AvatarContainerProps>`
  width: ${({ $size = avatarSize }) => $size}px;
  height: ${({ $size = avatarSize }) => $size}px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.foreground};
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
  flex-shrink: 0;
  transform: translateZ(0);
  ${media.mobile`
    width: ${({ $size = avatarSizeMobile }: AvatarContainerProps) => $size}px;
    height: ${({ $size = avatarSizeMobile }: AvatarContainerProps) => $size}px;
  `}
`;

const AvatarImage = styled(LazyLoadImage).attrs({
  effect: 'opacity',
})`
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  will-change: opacity;
  transition: opacity 600ms ease;
  transform: translateZ(0);
  display: inline;
`;

export type AvatarProps = {
  src?: string;
  className?: string;
  onClick?: () => void;
  size?: number;
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  className,
  onClick,
  size,
}) => (
  <AvatarContainer className={ className } onClick={ onClick } $size={ size }>
    {
      src ? (
        <AvatarImage src={ replaceIPFS(src) } />
      ) : null
    }
  </AvatarContainer>

);

export default Avatar;

/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import qs from 'qs';
import { defaultInitialValues, FilterFormData } from '~/hooks/useSearchObjkts';
import { filterNonNull } from '~/utils';
import { MIMETYPE_LIST } from '~/utils/mime';

export const useFilterQueryParams = (): FilterFormData => {
  const router = useRouter();
  const {
    mime = '',
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    name,
    ...query
  } = router.query;
  // eslint-disable-next-line no-underscore-dangle
  const mime_ = (mime as string).split(',').filter(Boolean);
  const result = {
    ...defaultInitialValues,
    ...query,
    mime: mime_,
    mimeTypes: mime_.reduce((res, m) => [
      ...res,
      ...(MIMETYPE_LIST.find(({ value }) => value === m)?.types || []),
    ], []),
  };
  return result as FilterFormData;
};

export const getNavUrl = (params, router) => {
  const path = router.asPath.split('?')[0];
  const query = filterNonNull(
    omit({
      ...router.query,
      ...params,
    }, [
      'name',
      'address',
      'section',
    ]),
  );
  return [path, qs.stringify(query)].filter(Boolean).join('?');
};

// eslint-disable-next-line no-underscore-dangle
export const setFilterQueryParams_ = (params, router) => {
  const url = getNavUrl(params, router);
  router.replace(url, url, { shallow: true });
};

export const useSetFilterQueryParams = () => {
  const router = useRouter();
  return (values) => setFilterQueryParams_(values, router);
};
